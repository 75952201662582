/* stylelint-disable */
.react-datepicker {
  @apply text-gray-1200 text-lg font-sans border-none min-h-[415px];
}

.react-datepicker,
.react-datepicker__month-container {
  @apply w-full;
}

.react-datepicker__header {
  @apply bg-white border-none pt-1;
}

.react-datepicker__week {
  @apply flex justify-between mb-4;
}

.react-datepicker__navigation {
  @apply top-2;
}

.react-datepicker__current-month {
  @apply mb-6;
}

.react-datepicker__day-names {
  @apply flex justify-between;
}

.react-datepicker__day-name {
  @apply text-v2-gray-900 text-center text-v2-nm font-bold;
}

.react-datepicker__day {
  @apply w-8 h-8 flex items-center justify-center text-v2-gray-900 text-v2-nm;
}

.react-datepicker__day:hover {
  @apply rounded-full;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent rounded-full text-v2-gray-900;
}

.react-datepicker__day--selected {
  @apply bg-v2-primary-400 rounded-full text-white;
}

.react-datepicker__day--disabled {
  @apply text-v2-gray-200;
}

.react-datepicker__day--today {
  @apply bg-v2-gray-50 rounded-full;
}

.react-datepicker__day--in-selecting-range {
  @apply rounded-full bg-v2-primary-25 text-white;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range
  ) {
  @apply bg-v2-primary-25 text-v2-primary-400;
}

.react-datepicker__day--range-start {
  @apply rounded-full !bg-v2-primary-400 !text-white;
}

.react-datepicker__day--range-end {
  @apply rounded-full !bg-v2-primary-400 !text-white;
}

.react-datepicker__day--in-range {
  @apply rounded-full bg-v2-primary-25 text-v2-primary-400;
}

.react-datepicker__day--in-range:hover {
  @apply bg-v2-primary-25;
}

.react-datepicker__day--selected:hover {
  @apply rounded-full;
}

.react-datepicker-time__caption {
  @apply text-lg font-semibold text-black mr-10;
}

.react-datepicker-time__input-container {
  @apply text-black bg-transparent;
}

.react-datepicker-time__input {
  @apply border-none rounded-md bg-transparent;
}

.react-datepicker-time__input > input {
  background-color: rgba(118, 118, 128, 0.12);
}

.react-datepicker__input-time-container {
  @apply pr-4 flex justify-between;
}
